import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from '@shared/organisation.service';
import { SessionService } from '@shared/session.service';
import { UserService } from '@shared/user.service';
import { catchError, forkJoin, of, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { jwtDecode } from 'jwt-decode';
import { DecodedToken } from '@shared/session.model';

@Component({
    selector: 'cp-login',
    templateUrl: './login.html',
    styleUrls: ['./login.scss'],
})
export class LoginComponent {
    private sub: any;

    private code: string = '';
    private state: string = '';

    constructor(
        private authService: AuthService,
        private sessionService: SessionService,
        private orgService: OrganisationService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        if (this.sub !== undefined) {
            this.sub.unsubscribe();
        }
        this.sub = this.route.queryParams.subscribe(async (params: any) => {
            this.code = params.code;
            this.state = params.state;
        });
    }

    async ngOnInit() {
        const authSuccess = await this.authService.exchangeGrantCodeAndSetAuthToken(
            this.code,
            this.state,
        );
        console.log('authSuccess', authSuccess);

        if (authSuccess) {
            const userSession = this.sessionService.get();
            const decodedToken = userSession.token ? jwtDecode<DecodedToken>(userSession.token) : null;
            
            if (decodedToken?.organizationMeta) {
                
                const firstOrgKey = Object.keys(decodedToken.organizationMeta)[0];
                const orgMeta = decodedToken.organizationMeta[firstOrgKey];

                userSession.user = {
                    id: decodedToken.sub || '',
                };
                
                userSession.organisation = {
                    id: orgMeta?.organisationId?.[0] || '',
                    name: orgMeta?.name?.[0] || firstOrgKey || '',
                };
                
                this.sessionService.set(userSession);
            }
            
            this.router.navigate(['/']);
        } else if (this.code && this.state) {
            await this.authService.redirectToLogout();
        } else {
            await this.authService.redirectToLogin();
        }
    }

    appChecksHasUserData() {
        return this.authService.hasUserExtendedData();
    }
}
