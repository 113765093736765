export enum LogLevel {
    Debug = 'debug',
    Info = 'info',
    Warn = 'warn',
    Error = 'error',
}

export type Environment = {
    stage: string;
    production: boolean;
    logLevel: LogLevel;
    appName: string;
    authenticationUrl: string;
    uiUrl: string;
    apiBaseUrl: string;
    sentryUrl: string;
    realm: string;
    clientId: string;
    webSocket: {
        url: string;
        reconnection: {
            attempt: number;
            delay: number;
            interval: number;
        };
    };
    networkUrls: { [key: string]: string };
    documents: {
        groups: {
            defaultTypeId: string;
        };
    };
    marketplace: {
        identifiers: { [key: string]: string };
        currencies: {
            display: string;
            value: string;
            address: string;
            type: 'fiat' | 'contract';
        }[];
    };
    payment: {
        stripePayments: {
            paymethodId: string;
        };
    };
    loadHotjar: boolean;
    featureWallets: boolean;
};
