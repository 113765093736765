import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Environment } from 'src/environments/env-type';
import { AuthService } from '../auth/services/auth.service';
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    private environment: Environment = environment;
    constructor(private authService: AuthService) {}

    intercept(
        request: HttpRequest<unknown>, 
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (!request.url.startsWith(this.environment.apiBaseUrl)) {
            return next.handle(request);
        }

        if (this.authService.isTokenExpired()) {
            if (this.isRefreshing) {
                return this.refreshTokenSubject.pipe(
                    filter((tokenAvailable) => tokenAvailable === true),
                    take(1),
                    switchMap(() => next.handle(
                        request.clone({ setHeaders: this.authService.authHeaders() })
                    )),
                );
            }
            return this.handleTokenRefresh(request, next);
        }

        const headers = this.authService.authHeaders();
        return next.handle(
            request.clone({ setHeaders: this.authService.authHeaders() })
        );
    }

    private handleTokenRefresh(
        request: HttpRequest<unknown>, 
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this.authService.refreshToken().pipe(
            switchMap(() => {
                this.isRefreshing = false;
                this.refreshTokenSubject.next(true);
                return next.handle(
                    request.clone({ setHeaders: this.authService.authHeaders() })
                );
            }),
            catchError((error: any) => {
                this.isRefreshing = false;
                this.refreshTokenSubject.next(false);
                return throwError(() => error);
            }),
        );
    }
}
