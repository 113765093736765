import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '@shared/session.service';
import { catchError, from, map, Observable, of, Subject } from 'rxjs';
import { environment } from '@app-env';
import { AUTH_TOKEN_VERSION } from '@shared/auth/classes/user';

interface AuthResponse {
    access_token: string;
    refresh_token: string;
}

interface AuthResult {
    success: boolean;
    token?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    loadingSubject: Subject<boolean> = new Subject();
    clientId!: string;

    constructor(
        private httpService: HttpClient, 
        private sessionService: SessionService, 
        route: ActivatedRoute
    ) 
    {
        route.queryParams.subscribe((params: any) => {
            this.clientId = params.client_id;
        });
    }

    loading(state: boolean) {
        this.loadingSubject.next(state);
    }

    private async generateStateAndGetAuthenticationUrl(): Promise<string> {
        const redirectUrl = encodeURIComponent(`${window.location.origin}/login`);
        const url = `${environment.authenticationUrl}realms/${environment.realm}/protocol/openid-connect/auth?client_id=${environment.clientId}&redirect_uri=${redirectUrl}&response_type=code&scope=organization`;
        return url;
    }

    async redirectToLogin(): Promise<void> {
        const url = await this.generateStateAndGetAuthenticationUrl();
        window.location.href = `${url}`;
    }

    async redirectToLogout(): Promise<void> {
        const redirectUrl = encodeURIComponent(`${window.location.origin}/login`,);
        const logoutUrl = `${environment.authenticationUrl}realms/${environment.realm}/protocol/openid-connect/logout?client_id=${environment.clientId}&post_logout_redirect_uri=${redirectUrl}`;
        
        console.log('logging out', logoutUrl);

        window.location.href = logoutUrl;
    }

    async exchangeGrantCodeAndSetAuthToken(code: string, state: string): Promise<boolean> {
        if (!code) return false;
        const url = `${environment.authenticationUrl}realms/${environment.realm}/protocol/openid-connect/token`;
        const redirectUrl = `${window.location.origin}/login`;

        const body = new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: environment.clientId,
            code: code,
            redirect_uri: redirectUrl,
        });

        try {
            const response = await this.httpService
                .post<{ access_token: string; refresh_token: string }>(url, body.toString(), {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                })
                .toPromise();

            if (response?.access_token) {
                this.sessionService.set({
                    token: response.access_token,
                    refresh_token: response.refresh_token,
                    version: AUTH_TOKEN_VERSION,
                });
                return true;
            } 
                
            return false;
            
        } catch (_err) {
            return false;
        }
    }

    refreshToken(): Observable<any> {
        const refreshToken = this.sessionService.get().refresh_token;
        if (!refreshToken) {
            console.error('No refresh token found');
            return of(null);
        }

        return from(
            fetch(
                `${environment.authenticationUrl}realms/${environment.realm}/protocol/openid-connect/token`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                        grant_type: 'refresh_token',
                        client_id: environment.clientId,
                        refresh_token: refreshToken,
                    }),
                },
            ),
        ).pipe(
            map(async (response) => {
                if (!response.ok) {
                    this.logout();
                    throw new Error('Token refresh failed');
                }
                const tokens = await response.json();
                this.sessionService.set({
                    token: tokens.access_token,
                    refresh_token: tokens.refresh_token,
                    version: AUTH_TOKEN_VERSION,
                });
                return tokens;
            }),
        );
    }

    authHeaders(): Record<string, string> {
        const session = this.sessionService.get();
        if (!session || !session.token) {
            // stale session, prompt user to login
            this.redirectToLogin();
            return {};
        }
        return {
            Authorization: `Bearer ${session.token}`,
        };
    }

    isLoggedIn(): boolean {
        if(this.sessionService.get().token)// && this.sessionService.get().organisation?.id) 
            return true;
        return false;
    }

    hasUserExtendedData(): boolean {
        if(this.sessionService.get().organisation?.id) return true;
        return false;
    }

    logout(): void {
        const redirectUrl = encodeURIComponent(`${window.location.origin}/logout`);
        const url = `${environment.authenticationUrl}/realms/${environment.realm}/protocol/openid-connect/logout?client_id=${environment.clientId}&post_logout_redirect_uri=${redirectUrl}`;
        window.location.href = url;
    }

    isTokenExpired(): boolean {
        const session = this.sessionService.get();
        if (!session?.token) return true;

        try {
            const [, payload] = session.token.split('.');
            if (!payload) return true;

            const { exp } = JSON.parse(atob(payload));
            return Date.now() >= exp * 1000 - 30000; // 30 seconds buffer
        } catch (error) {
            console.error('Token parsing failed:', error);
            return true;
        }
    }

    getTokenExpirationDate(): Date | null {
        const session = this.sessionService.get();
        if (!session?.token) return null;

        try {
            const [, payload] = session.token.split('.');
            if (!payload) return null;

            const { exp } = JSON.parse(atob(payload));
            return new Date(exp * 1000);
        } catch (error) {
            console.error('Token expiration date parsing failed:', error);
            return null;
        }
    }
}
