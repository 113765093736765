import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { SessionService } from '@shared/session.service';

@Component({
    selector: 'cp-logout',
    templateUrl: './logout.html',
    styleUrls: ['./logout.scss'],
})
export class LogoutComponent {
    constructor(
        private authService: AuthService,
        private sessionService: SessionService
    ) {}

    async ngOnInit() {
        console.log('logging out');
        this.sessionService.clear();
        this.authService.redirectToLogout();
    }
}
