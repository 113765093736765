/**
 * Documents Portal API
 * This document specifies the Document endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export enum OcrProvider {
    Textract = <any> 'TEXTRACT',
    GoogleDocumentAi = <any> 'GOOGLE_DOCUMENT_AI'
}
