import { Injectable } from '@angular/core';
import { UserSession, PermissionEnum, DecodedToken } from '@shared/session.model';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@app-env';
import { jwtDecode } from 'jwt-decode';
import { isObject, isString } from 'lodash';

@Injectable({ providedIn: 'root' })
export class SessionService {
    key: string;
    private sessionSubject: BehaviorSubject<UserSession | null>;

    constructor() {
        this.key = environment.appName;
        const contents = this.get();
        this.sessionSubject = new BehaviorSubject<UserSession | null>(contents);
    }

    get(): UserSession {
        const contents = localStorage.getItem(this.key);
        return contents ? (JSON.parse(contents) as UserSession) : {};
    }

    set(userSession: UserSession) {
        const dataToken: DecodedToken = userSession.token
                ? jwtDecode<DecodedToken>(userSession.token)
                : ({} as DecodedToken);

            const orgData = this.getOrganisationData(userSession.token);

            userSession.display = {
                id: dataToken.sub || '',
                firstName: dataToken.given_name || '',
                lastName: dataToken.family_name || '',
                org: orgData?.name || '',
            };

            userSession.user = {
                id: dataToken.sub,
            };

            userSession.organisation = {
                id: orgData?.id || dataToken.sub,
                name: orgData?.name || '',
            };

            localStorage.setItem(this.key, JSON.stringify(userSession));
            this.sessionSubject.next(userSession);
    }

    clear() {
        localStorage.removeItem(this.key);
        const preserveCookies = ['_ga', '_gid']; 
        for (const cookie of document.cookie.split(';')) {
            const name = cookie.split('=')[0].trim();
            if (!preserveCookies.includes(name)) {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            }
        }
        
        this.sessionSubject.next(null);
    }

    getSessionChanges() {
        return this.sessionSubject.asObservable();
    }

    getOrganisationData(token?: string) {
        const sessionToken = token || this.get().token;

        if (sessionToken) {
            const decodedToken: DecodedToken = jwtDecode(sessionToken);
            const { organizationMeta } = decodedToken;

            if (organizationMeta && typeof organizationMeta === 'object') {
                // Get the first organization key (e.g., "FSCO")
                const orgName = Object.keys(organizationMeta)[0];
                if (orgName) {
                    const orgData = organizationMeta[orgName];
                    
                    return {
                        id: orgData?.organisationId?.[0] || '',
                        name: orgData?.name?.[0] || orgName || '',
                    };
                }
            }
        }
        return null;
    }

    isSuperUser(): boolean {
        const token = this.get();
        return token.user?.superuser || false;
    }
    
}
