import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FscoComponentsModule } from '@fsco-components/fsco-components.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiService } from '@shared/api.service';
import { AuthGuard } from '@shared/auth/guards/auth.guard';
import { LoginComponent } from '@shared/auth/login/login';
import { AuthService } from '@shared/auth/services/auth.service';
import { NavComponent } from '@shared/nav/nav.component';
import { RedirectGuard } from '@shared/redirect-guard/classes/redirect-guard';
import { SessionService } from '@shared/session.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PlanGuard } from '@shared/auth/guards/plan.guard';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';

export const OCR_SERVICE_TOKEN = new InjectionToken<string>('OCR_SERVICE_TOKEN');
export const DOC_SERVICE_TOKEN = new InjectionToken<string>('DOC_SERVICE_TOKEN');
export const OCR_SERVICE = new InjectionToken<ApiService>('OCR_SERVICE');
export const DOC_SERVICE = new InjectionToken<ApiService>('DOC_SERVICE');
export const PORTAL_API_BASE_URL = new InjectionToken<string>('PORTAL_API_BASE_URL');

@NgModule({
    declarations: [AppComponent, HomeComponent, LoginComponent, NavComponent],
    imports: [
        MessagesModule,
        ToastModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FscoComponentsModule,
        FontAwesomeModule,
        ButtonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        {
            provide: OCR_SERVICE_TOKEN,
            useValue: 'https://mbupwfnz7u6z3rwb6o47b6ecse0nelkv.lambda-url.ap-southeast-2.on.aws',
        },
        // { provide: OCR_SERVICE_TOKEN, useValue: 'https://vl8st1ix60.execute-api.ap-southeast-2.amazonaws.com/dev' },
        { provide: DOC_SERVICE_TOKEN, useValue: 'https://doc-service.com' },
        {
            provide: OCR_SERVICE,
            useFactory: (http: HttpClient, baseUrl: string) => new ApiService(http, baseUrl),
            deps: [HttpClient, OCR_SERVICE_TOKEN],
        },
        {
            provide: DOC_SERVICE,
            useFactory: (http: HttpClient, baseUrl: string) => new ApiService(http, baseUrl),
            deps: [HttpClient, DOC_SERVICE_TOKEN],
        },
        { provide: PORTAL_API_BASE_URL, useValue: environment.apiBaseUrl },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        AuthGuard,
        PlanGuard,
        AuthService,
        SessionService,
        RedirectGuard,
        MessageService,
        ConfirmationService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
